import BaseNewsIndexListLayout from "../../../../base/components/layouts/news-index-list/index.jsx";
import V3NewsIndexListLayout from "../../../../v3/components/layouts/news-index-list/index.jsx";
import V4NewsIndexListLayout from "../../../../v4/components/layouts/news-index-list/index.jsx";
import V5NewsIndexListLayout from "../../../../v5/components/layouts/news-index-list/index.jsx";
import V6NewsIndexListLayout from "../../../../v6/components/layouts/news-index-list/index.jsx";

import ThemeContainer from "../../../theme-container.jsx";

export default class NewsIndexListLayoutThemeContainer extends ThemeContainer {
    constructor(props) {
        super(props);
    }

    render() {
        switch (this.theme.name) {
            case "v3":
                return <V3NewsIndexListLayout {...this.props} />;
            case "v4":
                return <V4NewsIndexListLayout {...this.props} />;
            case "v5":
                return <V5NewsIndexListLayout {...this.props} />;
            case "v6":
                return <V6NewsIndexListLayout {...this.props} />;
            default:
                return <BaseNewsIndexListLayout {...this.props} />;
        }
    }
}
