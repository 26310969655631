import { decode } from "../../../../base/helpers/html-helper.js";
import ActionPanel from "../../../../base/components/cms/action-panel.jsx";

export default class NewsItemLayout extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const item = this.props;
        if (!item) {
            return null;
        }

        let dates = [];
        if (item.dates) {
            item.dates.map((date, i) => {
                dates.push(<p key={i}>{date}</p>);
            });
        }

        let images = [];
        if (item.images) {
            const galleryId = "image-gallery-" + item.id;
            item.images.map((image, i) => {
                images.push(
                    <div className="img-container" key={i}>
                        <a
                            href={image.urls.big}
                            className="popup-image"
                            rel={galleryId}
                        >
                            <img
                                src={image.urls.thumb}
                                alt="pic"
                                className="img-prev"
                            />
                            <div className="open-img"></div>
                            <div className="expand-img"></div>
                        </a>
                    </div>
                );
            });

            images = <div className="image-prev">{images}</div>;
        }

        let files = [],
            links = [];
        if (item.files) {
            item.files.map((file, i) => {
                links = [];
                if (file.showUrl) {
                    links.push(
                        <a href={file.showUrl} target="_blank" key="show-url">
                            (Посмотреть)
                        </a>
                    );
                }
                if (file.downloadUrl) {
                    links.push(
                        <a
                            href={file.downloadUrl}
                            target="_blank"
                            key="download-url"
                        >
                            (Скачать)
                        </a>
                    );
                }
                if (!links.length && file.url) {
                    links.push(
                        <a href={file.url} target="_blank" key="url">
                            (Скачать)
                        </a>
                    );
                }
                files.push(
                    <div className="download" key={i}>
                        <div className="info">
                            <div className={"icon " + file.ext}></div>
                            <span>{file.caption}</span>
                        </div>
                        <div className="links">{links}</div>
                    </div>
                );
            });

            files = <div className="files-list">{files}</div>;
        }

        let annotation;
        if (this.props.annotation) {
            annotation = (
                <div className="annotation">{this.props.annotation}</div>
            );
        }

        return (
            <div className="pubitem">
                <h2 className="text-caption show-text">
                    {item.caption}
                    <ActionPanel {...this.props.cms} />
                </h2>
                <div className="sub">
                    <span className="name">{item.date}</span>
                </div>
                {annotation}
                <div
                    className="body"
                    dangerouslySetInnerHTML={{ __html: decode(item.body) }}
                />
                {images}
                {files}
                <div className="view-counts">
                    <p>
                        Просмотров всего: {item.visits_total}, сегодня:{" "}
                        {item.visits_today}
                    </p>
                </div>
                <div className="dates-list">{dates}</div>
            </div>
        );
    }
}
