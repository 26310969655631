import ListItem from "./list-item.jsx";
import ActionInlinePanel from "../../../../base/components/cms/action-inline-panel.jsx";

export default class NewsListLayout extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let list = [];
        if (this.props.list) {
            this.props.list.map(function (item) {
                list.push(<ListItem key={item.id} {...item} />);
            });
        }

        return (
            <div className="pubs-container">
                <ActionInlinePanel {...(this.props.cms || {})} />
                <div className="pubs-gallery viewmode-gallery-big-first">
                    {list}
                </div>
            </div>
        );
    }
}
