import LoadingPopup from "./components/loading-popup.jsx";
import newId from "../helpers/new-id.js";

import ErrorLayout from "../components/layouts/error.jsx";
import NewsListLayout from "../../theme-containers/components/layouts/news-list/index.jsx";
import NewsIndexListLayout from "../../theme-containers/components/layouts/news-index-list/index.jsx";
import NewsSideListLayout from "../../theme-containers/components/layouts/news-side-list/index.jsx";
import NewsItemLayout from "../../theme-containers/components/layouts/news-item/index.jsx";

import * as LayoutActions from "../actions/layout-actions.js";
import * as RouterActions from "../actions/router-actions.js";
import * as NewsListActions from "../actions/news-list-actions.js";

import * as Store from "../../store.js";

import { getNestedValue } from "../../../components/helpers/objects-helper.js";

export default class Application extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                loading: {
                    show: true,
                },
            },
        };

        this.browserStateListener = function (e) {
            RouterActions.loadUrlLayout(document.location.href);
        };

        Store.onUpdate((params) => {
            if (!params.path || params.path == "layout") {
                let newState;
                newState = Store.getStore();
                this.setState(newState);
            }
        });
    }

    componentDidMount() {
        // RouterActions.loadUrlLayout(document.location.href);
        LayoutActions.loadHomeLayout();
        window.addEventListener("popstate", this.browserStateListener);
    }

    componentWillUnmount() {
        window.removeEventListener("popstate", this.browserStateListener);
    }

    render() {
        let loading = getNestedValue(this.state, "data", "loading") || {};
        return (
            <div
                className={
                    "ns-page-layouts-container" +
                    (loading.show ? " loading" : "")
                }
                key={newId("layout-")}
            >
                {this.getLayout()}
                <LoadingPopup {...loading} />
            </div>
        );
    }

    getLayout() {
        switch (this.state.layout) {
            case "news-list":
                return (
                    <NewsListLayout {...this.state.data} {...NewsListActions} />
                );
            case "news-index-list":
                return (
                    <NewsIndexListLayout
                        {...this.state.data}
                        {...NewsListActions}
                    />
                );
            case "news-side-list":
                return (
                    <NewsSideListLayout
                        {...this.state.data}
                        {...NewsListActions}
                    />
                );
            case "news-item":
                return <NewsItemLayout {...this.state.data} />;
            case "error":
                return <ErrorLayout {...this.state.data} />;
        }
    }
}
